import React, { useState, useEffect, useTheme, useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
import Layout from "./Layout";
import Footer from "./Footer";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Tooltip, Grid,Dialog, SvgIcon, DialogContent, DialogActions, DialogTitle, CircularProgress, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import axios from "axios";
import {
  addLinkedAccount,
  fetchDashboardData,
  clearDashboardError
} from "../actions/addLinkedAccounts.js";
import { Link } from "react-router-dom";

import useMediaQuery from '@material-ui/core/useMediaQuery';



const withStyles = makeStyles(() => ({
  dashboardRoot: {
    minHeight: "100vh",
    width: "100%",
    // display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "auto",
  },
  root: {
    "&[disabled]": {
      color: "grey",
      cursor: "default",
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  linkedAssetWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    padding: "15px",
  },
  linkedAssetNum: {
    fontSize: "44px",
    fontWeight: "500",
  },
  linkedAssetTitle: {
    fontSize: "18px",
    fontWeight: "300",
  },
  dbTopText: {
    paddingTop: "3%",
    paddingLeft: "5%",
    paddingRight: "3%",
    paddingRight: "2%",
    textAlign: "left"
  },
  linkedAccountsCardsWrapper: {
    justifyContent: "space-evenly",
    whiteSpace: "nowrap",
    position: "relative",
  },
  linkedAccountsCardsWhite: {
    overflowX: "scroll",
  },
  linkedAccountsCards: {
    padding: "15px",
    maxWidth: "12vh",
    height: "15vh",
    marginRight: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "20px",
    boxShadow: "0 6px 22px 0 RGBA(91, 91, 91, 0.5)",
    display: "inline-block",
  },
  linkAccountsAddHex: {},
  requestIcon: {
    // height: "auto",
    // margin: "auto",
    // marginTop: "0px",
    // marginBottom: "10px",
    width: "30%",
    marginTop: "4%",
    margin: "auto",
    marginBottom: "6%"
  },
  requestCardTitle: {
    marginBottom: "10px",
    marginTop: "0",
    fontSize: "1rem",
    textAlign: "center",
    fontWeight: "700",
    color: "#313131",
  },
  fundsProofCardWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  fundsProofWrapper: {
    background: "#ffffff",
    borderRadius: "51px",
    marginLeft: '5%',
    marginRight: '5%',
    background: "#ffffff",
    '@media (max-width: 899px)' : {
      marginLeft: '0%',
      marginRight: '0%',
      paddingLeft: '5%',
      paddingRight: '5%',
      height: '100%'
    }
  },
  fundsProofHeader: {
    color: "#313131",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "700",
    marginTop: "15px",
  },
  fundsProofSubHeader: {
    fontSize: "16px",
    color: "#313131",
    fontWeight: "600",
    textAlign: "left",
  },
  fundsProofHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "40px",
    marginLeft: "30px",
    marginBottom: "20px",
  },
  notificationsWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#313131",
    marginTop: "20px",
    fontSize: "1.0rem",
    "& p": {
      fontWeight: "700",
    },
  },
  newMessagesWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
    background: "rgba(210,227,246,0.30)",
    borderRadius: "14px",
    height: "40px",
  },
  messageCount: {
    borderRadius: "20px",
    backgroundColor: "#44a2a0",
    height: "60%",
    width: "25px",
    color: "#fff",
    margin: "8px",
    fontSize: "14px",
  },
  bankName1: {
    fontSize: "11px",
    fontWeight: "400",
    textAlign: "left",
    float: "left",
    width: "100%",
  },
  bankAmount: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#464646",
    marginBottom: "10px",
    float: "left",
    width: "100%",
  },
  accountImg: {
    float: "left",
    width: "35%",
    marginBottom: "10px",
  },
  paperRoot: {
    width: "100%",
    margin: "0 auto",
  },
  linkAccountsAddHex: {
    width: "auto",
    height: "30px",
    marginBottom: "auto",
  },
  bankName: {
    fontSize: "0.9rem",
    textAlign: "left",
  },
  accountValue: {
    fontSize: "1rem"
  },
  bankNameAccountCount: {
    fontSize: "0.65rem",
    textAlign: "left",
  },
  bankNameWrapper: {
    marginBottom: "5px"
  },
  requestCard: {
    height: "15vw",
    width: "25vw",
    minWdth: "25vw",
    borderRadius: "14px",
    marginRight: "5px",
    marginLeft: "5px",
    boxShadow: "0 6px 22px 0 rgba(91,91,91,0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center", 
    marginTop: "15px",
    '@media (max-width: 899px)' : {
      width: "auto",
      height: "auto"
    }
  },
  fundsProofCardWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  fundsProofHeader: {
    color: "#313131",
    textAlign: "left",
  },
  fundsProofSubHeader: {
    fontSize: "0.75rem",
    color: "#313131",
    textAlign: "left",
  },
  notificationsWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#313131",
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontSize: "1.0rem",
  },
  newMessagesWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
    background: "rgba(210,227,246,0.30)",
    borderRadius: "14px",
    height: "40px",
  },
  messageCount: {
    borderRadius: "20px",
    backgroundColor: "#44a2a0",
    height: "60%",
    width: "25px",
    color: "white",
    margin: "8px",
  },
  popover: {
    backgroundColor: "#000",
    opacity: ".7",
    color: "#fff",
  },
  popoverDiv: {
    textAlign: "left",
    fontSize: "1.5rem",
    marginLeft: "2vh",
    marginRight: "2vh",
    padding: "2vh",
    color: "white",
    backgroundColor: "#13474A",
    height: "16vh",
    width: "30vh",
    borderRadius: "2vh",
  },
  popoverCloseButton: {
    color: "white",
    float: "right",
    left: "3.5vh",
    bottom: "2vh",
    fontSize: "1.3rem",
  },
  popoverOutline: {
    outline: "solid grey 2px",
  },
  bankLogos: {
    height: "22px",
    width: "22px",
    margin: "auto",
  },
  bankLogoWrapper: {
    display: "flex",
    justifyContent: "center",
    /* vertical-align: -webkit-baseline-middle, */
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 5px rgb(187 183 183 / 26%)",
    borderRadius: "30px",
    width: "27px",
    height: "27px",
  },
  linkedAccountsBankWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  loadingDialog: {
    backgroundColor: "#000",
    opacity: ".7",
    color: 'white',
    paddingBottom: "20px"
  },
  spinner: {
      marginRight: '20px'
      
  },
  dialogText: {
      fontSize: '1.15rem'
  },
  dialogButton: {
    color: "white"
  },
  popovers: {
    zIndex: "10",
  },
  notificationCard: {
    marginTop: "1.5vh",
    marginBottom: "1.5vh",
    // width: "90%",
    height: "10vh"
  },
  dontShowOnMobile: {
    '@media (max-width: 899px)' : {
      display: 'none'
    }
  },
  headerText: {
    marginBottom: 0,
    marginTop: 0,
    fontWeight: 400,
    '@media (max-width: 899px)' : {
      paddingBottom: '3%'
    }
  },
  leftGrid: {
    borderRight: '#bbb  solid', paddingLeft: '5%', paddingTop: '2%', marginTop: '2%', marginBottom: '5%', paddingLeft: '4% !important',
    '@media (max-width: 899px)' : {
      borderRight: 'none',
      marginBottom: "0%"
    }
  },
  leftGridTitle: {
    'margin':  0,'fontWeight': 'bold','fontSize': '70%', marginBottom: '5%',
    '@media (max-width: 899px)' : {
      marginBottom: 0,
      paddingLeft: '1%',
      fontSize: '100%'
    }
  },
  rightGrid: {
    color: '#000', textAlign: 'left', paddingLeft: '3%', paddingTop: '2%', marginTop: '2%', maxHeight: '60vh', overflowX: "hidden",
    '@media (max-width: 899px)' : {
      maxHeight: '6000vh'
    }
  },
  rightGridTitle: {
    'margin':  0,'fontWeight': 'bold','fontSize': '70%',
    '@media (max-width: 899px)' : {
      fontSize: '100%'
    }
  },
  mainGrid: {
    color: '#000', textAlign: 'left', paddingRight: "3%", paddingBottom: "2%",
    '@media (max-width: 899px)' : {
      paddingBottom: "0%"
    }
  },
  cardTopLine: {
    fontWeight: "bold", 
    fontSize: ".4em",
    '@media (max-width: 899px)' : {
      fontSize: ".7em",
    }
  },
  cardSecondaryLine: {
    fontSize: ".35em",
    '@media (max-width: 899px)' : {
      fontSize: ".6em",
    }
  },
  notificationIcon: {
    height: '45%',
    objectFit: "cover",
    marginLeft: '2%',
    marginTop: '2%'
  }
}));

const Dashboard = (props) => {
  const classes = withStyles();
  const dispatch = props.dispatch;
  const { dashboardData, dashboardDataLoading, dashboardErrorMsg, dashboardError } = props;
  const [linkToken, setLinkToken] = useState(null);
  const LS_FIRST_TIME_MODALS = "pvFirstTimeModals";
  const showModalsLocalStorage = localStorage.getItem(LS_FIRST_TIME_MODALS);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const onSuccess = useCallback(
    (token, metadata) => {
      dispatch(addLinkedAccount(token, metadata));
    },
    [dispatch]
  );

  useEffect(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/create_link_token`
      );
      console.log(response);
      console.log(`link token: ${response.data.link_token}`);
      setLinkToken(response.data.link_token);
    } catch (error) {
      console.log(error);
    }
  }, [setLinkToken]);

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, []);

  console.log('dashboard data', dashboardData ?? dashboardData?.messages)

  const handleClick = () => {
    if (ready) {
      open();
    }
  };

  const config = {
    token: linkToken !== null ? linkToken : "",
    onSuccess,
  };

  const { open, ready, err } = usePlaidLink(config);

  const formatNumbers = (num) => {
    if (num % 1 !== 0) {
      let localeNum = num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
      return localeNum
    } else {
      return num.toLocaleString();
    }
  };

  const sumBankAccountValue = (account) => {
    let accountSum = account.accounts.map(act => act.value).reduce((a,b) => a+b);
    return formatNumbers(accountSum)
  }

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
    dispatch(clearDashboardError());
  };

  const getNotificationIcon = (msgStatus) => {
    const MESSAGE_STATUS_CHOICES = [
      "Proof-of-funds Request Email Sent.",
      "Proof-of-funds Request Email Opened.",
      "Proof-of-funds Request sent to Bank",
      "Proof-of-funds Report received from Bank",
    ];
    switch(msgStatus) {
      case 'Proof-of-funds Request Email Sent.':
        return "/icons/Status=Sent-view-new.png"; 
      case 'Proof-of-funds Request Email Opened.':
        return "/icons/Status=Opened-view-new.png";
      case 'Proof-of-funds Request sent to Bank':
        return "/icons/Status=Sent-view-new.png";
      case 'Proof-of-funds Report received from Bank':
        return "/icons/Status=ReceivedPoF-view-new@2x.svg";
      default:
        return "/icons/Status=Sent-view-read.png";
    }
  };


    return (
      <div className={classes.dashboardRoot}>
        <Layout pageTitle={props.title} />
        <div className={classes.dbTopText}>
          <h2 className={classes.headerText}>Your Dashboard Hub</h2>
          <p className={classes.dontShowOnMobile} style={{marginBottom: '3%', fontSize: '70%', marginTop: "0", fontWeight: "400"}}>Centralize your tasks—request funds, send emails, and calculate ROI—all from here.</p>
        </div>
        <div className={classes.fundsProofWrapper}>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} md={8} className={classes.leftGrid}>
              <p className={classes.leftGridTitle}>Start a New Task</p>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Link to="/requestreport" style={{ textDecoration: 'none' }}>
                    <Card className={classes.requestCard}>
                      <img
                        className={classes.requestIcon}
                        src="/icons/BigIcon_Request.png"
                      />
                      <h3 className={classes.requestCardTitle}>
                        Request Proof of Funds
                      </h3>
                    </Card>
                  </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Link to="/fastrealty" style={{ textDecoration: 'none' }}>
                      <Card className={classes.requestCard}>
                        <img
                          className={classes.requestIcon}
                          src="/icons/BigIcon_Request.png"
                        />
                        <Typography className={classes.requestCardTitle} style={{ marginBottom: 0 }}>
                          Generate an Email
                        </Typography>
                        {/* <Typography style={{ fontWeight: 500 }}>
                          Coming soon...
                        </Typography> */}
                      </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Link to="/fastrealtycalllist" style={{ textDecoration: 'none' }}>
                      <Card className={classes.requestCard}>
                        <img
                          className={classes.requestIcon}
                          src="/icons/BigIcon_Request.png"
                        />
                        <Typography className={classes.requestCardTitle} style={{ marginBottom: 0 }}>
                          Call List Verification
                        </Typography>
                        {/* <Typography style={{ fontWeight: 500 }}>
                          Coming soon...
                        </Typography> */}
                      </Card>
                    </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} className={classes.rightGrid} >
              <p className={classes.rightGridTitle}>Notifications</p>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {dashboardData && dashboardData.messages && dashboardData.messages.map((msg) => (
                    <TableRow
                      key={msg.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <Card className={classes.notificationCard} style={{justifyContent: "left", display: "flex", paddingRight:"1%"}}>
                        <img
                          className={classes.notificationIcon}
                          src={getNotificationIcon(msg.message_status)}
                        />
                        <div style={{height: "100%", display:"flex", flexDirection: "column", justifyContent: "center", paddingLeft: "4%", width: "73%"}}>
                          <Typography className={classes.cardTopLine}>{msg.message_status}</Typography>
                          <Typography noWrap="true" className={classes.cardSecondaryLine} style={{marginTop: "1%"}}>{msg.to_user}</Typography>
                          <Typography noWrap="true" className={classes.cardSecondaryLine}>{(new Date(msg.message_date).toDateString())}</Typography>
                          <Typography className={classes.cardSecondaryLine} style={{color: "#b5b5b5", marginTop: "1%"}}>{(new Date(msg.message_date).toDateString())}</Typography>
                        </div>
                      </Card>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>

        </div>
        
        <Dialog
            open={(dashboardDataLoading) && !dashboardError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress className={classes.spinner} color="inherit" />
                      Loading Your Dashboard...
                </Typography>
            </DialogContent>
        </Dialog>
        <Dialog
            open={dashboardError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className={classes.loadingDialog}>ERROR</DialogTitle>
            <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                        {dashboardErrorMsg ? dashboardErrorMsg : "Failed to load dashboard. Please try again."}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.loadingDialog}>
                <Button className={classes.dialogButton} onClick={() => handleErrorModalClose()}>
                Okay
                </Button>
            </DialogActions>
        </Dialog>
      </div>
    );
};

export default connect((state) => ({
  dashboardData: state.dashboardData,
  dashboardDataLoading: state.dashboardDataLoading,
  dashboardErrorMsg: state.dashboardErrorMsg,
  dashboardError: state.dashboardError,
}))(Dashboard);
